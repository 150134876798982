/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css, jsx } from 'theme-ui'
import Divider from '@components/Divider'

const styles = {
  wrapper: {
    display: "flex",
    flexWrap: [`wrap`, `nowrap`, `nowrap`],
    mt: -4,
    mx: -4,    
  },
  dual: {
    minHeight: [`7rem`,`23rem`,`23rem`],
    bg: `omegaLighter`,
    borderRadius: `lg`,
    width: '100%',
    bg: `omegaLighter`,
    maxHeight: [`12rem`,`50rem`,`50rem`],
  },
  dual1: {
    borderRadius: (t) => [`${t.radii.lg} ${t.radii.lg} 0 0`,`${t.radii.lg} 0 0 0`],
  },
  dual2: {
    borderRadius: (t) => [0,`0 ${t.radii.lg} 0 0`],
  },
  regular: {
    minHeight: `23rem`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
  },
  wide: {
    borderRadius: `lg`,
  },
  full: {
    bg: `omegaLighter`,
  },
  inCard: {
    bg: `omegaLighter`,
    borderRadius: (t) => `${t.radii.lg} ${t.radii.lg} 0 0`,
    mt: -4,
    mx: -4,
  },
  fullImg: {
    position: `static`,
    display: `block`,
  },
}

export const PostImage = ({ thumbnail, wide, full, dual, inCard }) => {
  if (!thumbnail) return null
  const variant =
    (wide && 'wide') || (full && 'full') || (dual && 'dual') || 'regular'
 
  if (thumbnail.dual1 && thumbnail.dual2) {
    return (
      <>
        <div sx={styles.wrapper}>
          <Img
            fluid={thumbnail.dual1}
            css={css({
              ...styles[variant],
              ...(inCard && styles.inCard),
              ...(dual && styles.dual1),
            })}
          />
          <Img
            fluid={thumbnail.dual2}
            css={css({
              ...styles[variant],
              ...(inCard && styles.inCard),
              ...(dual && styles.dual2),
            })}
          />
        </div>
        <Divider space={3} />
      </>
    )
  } else
    return (
      <>
        <Img
          fluid={thumbnail.hero}
          css={css({ ...styles[variant], ...(inCard && styles.inCard) })}
          imgStyle={
            (variant === 'wide' || variant === 'full') && styles.fullImg
          }
        />
        <Divider space={3} />
      </>
    )
}

PostImage.propTypes = {
  wide: PropTypes.bool,
  full: PropTypes.bool,
  inCard: PropTypes.bool,
}
