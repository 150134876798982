import React from 'react'
import { Stack, Main } from '@layout'
import { Card as CardComponent } from 'theme-ui'
import CardList from '@components/CardList'
import { PostImage, PostTagsShare } from '@widgets/Post'
import { Link } from 'theme-ui'

import Card from '@components/Card'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Reviews = ({ location, ...props }) => {
  const { pageContext: { services = {}, product1, slug, image } = {} } = props
  const post = {
    title: `${product1.name} Reviews`,
    slug,
    thumbnail: {
      hero: image && image.childImageSharp.fixed,
    },
    category: {
      color: '#e9d8fd',
      name: 'Reviews',
      slug: '/category/reviews/',
    },
  }
  const reviewPosts =
    product1.reviews &&
    testReviews.map((review, i) => {
      let body = (
        <span>
          - {review.author} from{' '}
          <Link
            {...{
              as: 'a',
              href: review.source,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          >
            <b>{new URL(review.source).hostname}</b>
          </Link>
        </span>
      )
      return {
        title: review.body,
        excerpt: body,
        id: i,
      }
    })

  return (
    <>
      <Seo title={post.title} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card
            {...post}
            variant='horizontal-hero'
            omitExcerpt
            omitMedia
            omitFooter
          />
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {/* <CardComponent variant='paper-lg'> */}
          <PostImage {...post} />

          <CardList
            nodes={reviewPosts}
            variant='horizontal'
            columns={[1, 2]}
            omitMedia
            omitFooter
          />
          <PostTagsShare {...post} location={location} />
          {/* </CardComponent> */}
        </Main>
      </Stack>
    </>
  )
}

export default Reviews

const testReviews = [
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
  {
    author: 'Jamie Test',
    source: 'https://reddit.com',
    body: 'Robinhood suxxxx lol xD',
  },
]
